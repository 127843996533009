import omit from 'lodash/omit';
import NextImage from 'next/image';
import { IImageAcf } from 'src/types/IImageAcf';
import ContainImage from '@components/views/ContainImage';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import { getImageAlt, getTextBetweenFigcaption } from 'src/utils/urlRegex';

export interface ImageProps {
  image: IImageAcf & { align: any };
  description?: string;
  epAnimationGeneratedClass?: string;
  className?: string;
  innerHTML: string;
}

const Image: React.FunctionComponent<ImageProps> = (props) => {
  const { image, description, className, innerHTML } = props;

  if (!image) return null;

  const { width, height } = image;
  const coreDescription = getTextBetweenFigcaption(innerHTML);
  const alt = getImageAlt(innerHTML);

  const InnerImage = () => (
    <>
      {width && height ? (
        <NextImage
          src={image.url}
          {...omit(image, 'sizes', 'id')}
          loading="lazy"
          alt={alt || ''}
        />
      ) : (
        <ContainImage src={image.url} loading="lazy" alt={alt || ''} />
      )}
    </>
  );

  return (
    <div
      className={className}
      style={{
        textAlign: image.align || 'left',
      }}
    >
      <InnerImage />

      {(!!description || !!coreDescription) && (
        <p
          className="text-xs text-neutral-4 max-w-768 mx-auto mt-16 text-left"
          dangerouslySetInnerHTML={{
            __html: description || coreDescription,
          }}
        />
      )}
    </div>
  );
};

export default Image;
